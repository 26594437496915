import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_OUTLINE } from "../../queries/outlineData";

import "./MobileFooter.scss";
import MobileFooterContent from "./MobileFooterContent";

const MobileFooter = () => {
  const { data, loading, error } = useQuery(GET_OUTLINE);

  let {
    params: { volume, chapter },
  } = useRouteMatch("/:year/:volume?/:chapter?");
  const selectedVolume =
    data && data.volumes && volume
      ? data.volumes.filter((vol) => vol.slug === volume)[0]
      : null;

  const selectedChapter =
    selectedVolume && selectedVolume.chapters && chapter
      ? selectedVolume.chapters.filter((chap) => chap.slug === chapter)[0]
      : null;

  if (loading) {
    return <div className="informational mobile-footer">Loading...</div>;
  }
  if (error) {
    return (
      <div className="informational mobile-footer">
        Error retriving ADR outline
      </div>
    );
  }

  return selectedChapter && !loading && !error ? (
    <MobileFooterContent currentChapter={selectedChapter} />
  ) : (
    <nav className="mobile-footer"></nav>
  );
};

export default MobileFooter;
