import React from "react";
import Graph from "./Venn";

import setInitialOptions from "./setInitialFigureOptions";
import initialOptions from "./initialToggleOptions";
import StateMap from "./StateMap";
import HSAMap from "./HSAMap";
import WorldMap from "./WorldMap";

const PanelInstance = ({ panel: figData, size, tab, toggle }) => {
  if (figData.chartType === "VENN DIAGRAM") {
    const options = setInitialOptions(
      initialOptions(),
      figData,
      { data: [figData.panel] },
      size,
      tab,
      toggle,
      figData.panel.panel
    );
    return <Graph options={options} />;
  } else if (figData.chartType === "STATE MAP" || figData.chartType === "MAP") {
    return <StateMap data={figData} size={size} tab={tab} toggle={toggle} />;
  } else if (figData.chartType === "HSA MAP") {
    return <HSAMap data={figData} size={size} tab={tab} toggle={toggle} />;
  } else if (figData.chartType === "WORLD MAP") {
    return <WorldMap data={figData} size={size} tab={tab} toggle={toggle} />;
  } else {
    const title =
      figData.panel?.panel === figData.figureNumber ? "" : figData.panel?.panel;

    const options = setInitialOptions(
      initialOptions(),
      figData,
      {
        ...figData.panel,
        data: figData.panel?.values,
      },
      size,
      tab,
      toggle,
      title
    );

    if (figData.chartType === "PIE CHART") {
      const values = figData?.panel?.values
        ? figData.panel.values
        : figData.none.values;
      options.series = [
        {
          data: values.series.map((sp) => ({
            color: sp.color,
            name: sp.name,
            y: sp.data[0],
          })),
        },
      ];
    }

    return <Graph options={options} />;
  }
};

export default PanelInstance;
