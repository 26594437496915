import React from "react";
import { useParams } from "react-router";
import ESRDAnalyticalMethods2020 from "./ESRDAnalyticalMethods2020";
import ESRDAnalyticalMethods2021 from "./ESRDAnalyticalMethods2021";
import ESRDAnalyticalMethods2022 from "./ESRDAnalyticalMethods2022";
import ESRDAnalyticalMethods2023 from "./ESRDAnalyticalMethods2023";

const ESRDAnalyticalMethods = () => {
  const { year } = useParams();
  if (year === "2020") {
    return <ESRDAnalyticalMethods2020 />;
  } else if (year === "2021") {
    return <ESRDAnalyticalMethods2021 />;
  } else if (year === "2022") {
    return <ESRDAnalyticalMethods2022 />;
  } else if (year === "2023") {
    return <ESRDAnalyticalMethods2023 />;
  } else {
    return null;
  }
};

export default ESRDAnalyticalMethods;
