import React from "react";
import "./App.scss";
import Header from "./components/header/Header";
import Content from "./components/content/Content";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import MobileFooterSwitch from "./components/nav/MobileFooterSwitch";
import MobileLogo from "./components/nav/USRDS_Logo_Acronym.svg";
import ErrorFallback from "./components/content/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import ApolloWrapper from "./ApolloWrapper";

function App() {
  return (
    <ApolloWrapper>
      <div className="app">
        {/* For printing */}
        <img
          src={MobileLogo}
          className="print-logo-img"
          alt="USRDS ADR Home Page"
          style={{ display: "none" }}
        />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/2023" />;
              }}
            />
            <Route path="/:year">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Header />
                <Content />
                <MobileFooterSwitch />
              </ErrorBoundary>
            </Route>
          </Switch>
        </Router>
      </div>
    </ApolloWrapper>
  );
}

export default App;
