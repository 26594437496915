import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "./HighchartsMixins";
import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";
import Header from "./graphComponents/Header";
import Graph from "./graphComponents/Graph";
import Summary from "./graphComponents/Summary";
import Tab from "./graphComponents/Tab";
import { useEffect } from "react";
import setInitialFigureOptions from "./setInitialFigureOptions";
import initialToggleOptions from "./initialToggleOptions";
import HSAMap from "./HSAMap";
import StateMap from "./StateMap";

const TabOnly = ({ serverdata, slug, size }) => {
  const data = serverdata;

  const dispatch = useDispatch();
  const graphState = useSelector(
    (state) => state.graphState[data.figureNumber]
  );

  // determine current possible tab values and select the default one selected
  const tabValues = Array.from(new Set(data.tabOnly.flatMap((x) => x.tab)));
  const cTab = graphState && graphState.tab ? graphState.tab : tabValues[0];
  const [currentTab, setTab] = useState(cTab);

  const [options, setOptions] = useState();

  useEffect(() => {
    let newOptions = {
      title: {
        text: currentTab,
      },
    };
    newOptions = setInitialFigureOptions(
      initialToggleOptions(),
      data,
      [],
      size,
      currentTab,
      null
    );

    setOptions(newOptions);
  }, [currentTab, data, size]);

  const tabDataSet = (newTab) => {
    setTab(newTab);
    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: newTab,
      },
    });
  };

  let graph = <Graph highcharts={Highcharts} options={options} size={size} />;
  if (data.chartType === "HSA MAP") {
    graph = <HSAMap data={data} size={size} tab={currentTab} />;
  } else if (data.chartType === "STATE MAP" || data.chartType === "MAP") {
    graph = <StateMap data={data} size={size} tab={currentTab} />;
  }

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header figureData={data} slug={slug} currentTab={currentTab} />
          <Tab
            currentTab={currentTab}
            tabDataSet={tabDataSet}
            tabValues={tabValues}
          />
        </>
      ) : (
        <></>
      )}
      {options ? graph : <></>}
      <Summary figureData={data} size={size} />
    </>
  );
};

export default TabOnly;
