import React from "react";
import Highcharts from "./HighchartsMixins";

import "./figure.scss";
import { useState } from "react";
import setInitialOptions from "./setInitialFigureOptions";
import Header from "./graphComponents/Header";
import Graph from "./graphComponents/Graph";
import Summary from "./graphComponents/Summary";
import TogglePane from "./graphComponents/TogglePane";
import Tab from "./graphComponents/Tab";
import { useDispatch, useSelector } from "react-redux";
import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";
import HSAMap from "./HSAMap";
import StateMap from "./StateMap";
import { useEffect } from "react";
import initialToggleOptions from "./initialToggleOptions";

const TabToggle = ({ serverdata, size, slug }) => {
  const data = serverdata;
  //data.id = serverdata.figureNumber.replace(/\s+/g, "");

  const dispatch = useDispatch();
  const graphState = useSelector(
    (state) => state.graphState[data.figureNumber]
  );

  // determine current possible tab values and select the default one selected
  const tabValues = Array.from(new Set(data.tab.flatMap((x) => x.tab)));
  const cTab = graphState && graphState.tab ? graphState.tab : tabValues[0];
  const [currentTab, setTab] = useState(cTab);

  // determine possible toggle values from the currently selected tab
  const togValues = data.tab.filter((y) => y.tab === currentTab)[0];
  const possibleToggleValues = Array.from(
    new Set(togValues ? togValues.values.flatMap((x) => x.toggle) : [])
  );

  // ugh, I'm so <sorry className=""></sorry>
  let iTog =
    data.figureNumber === "Figure 1.6" &&
    data.volume === "chronic-kidney-disease"
      ? "2015-2018"
      : possibleToggleValues[0];

  if (data.figureJson) {
    try {
      const figJson = JSON.parse(data.figureJson);
      if (figJson.defaultToggle) {
        iTog = figJson.defaultToggle;
      }
    } catch {
      console.warn("tried to parse non-json json");
    }
  }
  //end of being sorry - not sorry

  const [toggleValues, setToggleValues] = useState(possibleToggleValues);
  const [toggle, setToggle] = useState(
    graphState && graphState.toggle ? graphState.toggle : iTog
  );

  let [options, setOptions] = useState();

  useEffect(() => {
    let newOptions = {
      title: {
        text: currentTab,
      },
    };
    const s = initialToggleOptions();
    newOptions = setInitialOptions(s, data, [], size, currentTab, toggle);
    setOptions(newOptions);
  }, [data, toggle, size, currentTab]);

  const toggleDataSet = (newToggle) => {
    setToggle(newToggle);

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: currentTab,
        toggle: newToggle,
      },
    });
  };

  const tabDataSet = (newTab) => {
    setTab(newTab);

    // set new possible toggle values for this series
    const toggleSet = data.tab.filter((y) => y.tab === newTab)[0];
    const newPossibleToggleValues = Array.from(
      new Set(toggleSet ? toggleSet.values.flatMap((x) => x.toggle) : [])
    );
    setToggleValues(newPossibleToggleValues);

    // if the current toggle value is in the new possible toggle value list,
    // don't change it, otherwise change it to the default
    let finalToggle = toggle;
    if (newPossibleToggleValues.indexOf(toggle) < 0) {
      finalToggle = newPossibleToggleValues[0];
      setToggle(newPossibleToggleValues[0]);
    }

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: newTab,
        toggle: finalToggle,
      },
    });
  };

  let graph = <Graph highcharts={Highcharts} options={options} size={size} />;
  if (data.chartType === "HSA MAP") {
    graph = <HSAMap data={data} size={size} toggle={toggle} tab={currentTab} />;
  } else if (data.chartType === "STATE MAP") {
    graph = (
      <StateMap data={data} size={size} tab={currentTab} toggle={toggle} />
    );
  }

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header
            figureData={data}
            currentToggle={toggle}
            slug={slug}
            currentTab={currentTab}
          />
          <Tab
            currentTab={currentTab}
            tabDataSet={tabDataSet}
            tabValues={tabValues}
          />
          {toggleValues.length > 1 ? (
            <TogglePane
              figureData={data}
              toggle={toggle}
              toggleValues={toggleValues}
              toggleDataSet={toggleDataSet}
              size={size}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {options ? graph : <></>}
      <Summary figureData={data} size={size} />
    </>
  );
};

export default TabToggle;
