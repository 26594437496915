import React from "react";
import { useParams } from "react-router";
import ReferenceTables2020 from "./ReferenceTables2020";
import ReferenceTables2021 from "./ReferenceTables2021";
import ReferenceTables2022 from "./ReferenceTables2022";
import ReferenceTables2023 from "./ReferenceTables2023";

const ReferenceTables = () => {
  const { year } = useParams();
  if (year === "2020") {
    return <ReferenceTables2020 />;
  } else if (year === "2021") {
    return <ReferenceTables2021 />;
  } else if (year === "2022") {
    return <ReferenceTables2022 />;   
  } else if (year === "2023") {
    return <ReferenceTables2023 />;  
  } else {
    return null;
  }
};

export default ReferenceTables;
