import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useRouteMatch } from "react-router-dom";
import { GET_OUTLINE } from "../../queries/outlineData";
import { getChapterPrefix } from "../../utils/chapterFormat";

const VolumeContent = () => {
  const { data } = useQuery(GET_OUTLINE);
  // match up current path with what sections are currently available
  const {
    params: { year, volume },
  } = useRouteMatch("/:year/:volume");

  const selectedVolume =
    data && data.volumes && volume
      ? data.volumes.filter((vol) => vol.slug === volume)[0]
      : null;
  return selectedVolume ? (
    <>
      <h1>{selectedVolume.title}</h1>
      <h2>Table of Contents</h2>
      <ul>
        {selectedVolume.chapters ? (
          selectedVolume.chapters.map((chap) => {
            const path = `/${year}/${selectedVolume.slug}/${chap.slug}`;
            return (
              <Link to={path} key={path}>
                <li>
                  {chap.chapterNumber && chap.chapterNumber > 0
                    ? `${getChapterPrefix(chap, selectedVolume)}${chap.title}`
                    : chap.title}
                </li>
              </Link>
            );
          })
        ) : (
          <></>
        )}
      </ul>
    </>
  ) : (
    <></>
  );
};

export default VolumeContent;
