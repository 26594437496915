import React from 'react';
import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';

import './Header.scss';
import Logo from '../nav/Logo';
import us_flag_small from "./us_flag_small.png"
import icon_dot_gov from "./icon-dot-gov.svg";
import icon_https from "./icon-https.svg"

function Header() {
const [open, setOpen] = useState(false);

    return (       
        <header>
            <div className='hhsbanner'>
                <img
                aria-hidden="true"
                src={us_flag_small}
                alt=""
                className="hhsbanner-flag"
            />
                <span>An official website of the United States government</span>
                <span
                    onClick={() => setOpen(!open)}
                    aria-controls="hhsbanner-collapse-text"
                    aria-expanded={open}
                    variant="link"
                    className="hhsbanner-link"
                >
                    Here's how you know&nbsp;
                </span>
                </div>
                <div>
                <Collapse in={open}>
                    <div id="hhsbanner-collapse-text" className="hhsbanner">
                        <div className="hhsbanner-text">
                            <img
                                src={icon_dot_gov}
                                role="img"
                                alt=""
                                aria-hidden="true"
                                className="hhsbanner-icon"
                            />                            
                            <p>
                                <strong>
                                    Official websites use .gov
                                </strong>
                                <br />
                                A <strong>.gov</strong> website belongs to an official government organization in the United States.
                            </p>
                        </div>
                        <div className="hhsbanner-text">
                            <img
                                src={icon_https}
                                role="img"
                                alt=""
                                aria-hidden="true"
                                className="hhsbanner-icon"
                            />                            
                            <p>
                                <strong>Secure .gov websites use HTTPS</strong><br />
                                A <strong>lock</strong> (&nbsp;
                                <span class="icon-lock"
                                    ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.5"
                                    height="9"
                                    viewBox="0 0 52 64"
                                    role="img"
                                    aria-labelledby="banner-lock-description-default"
                                    focusable="false"
                                    >
                                    <title id="banner-lock-title-default">Lock</title>
                                    <desc id="banner-lock-description-default">Locked padlock icon</desc>
                                    <path
                                        fill="#000000"
                                        fill-rule="evenodd"
                                        d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                                    />
                                    </svg> </span
                                >) or <strong>https://</strong> means you've safely connected to
                                the .gov website. Share sensitive information only on official,
                                secure websites.
                            </p>                         
                        </div>                        
                    </div>
                </Collapse>
            </div>
            <Logo />
        </header>
    )
}

export default Header