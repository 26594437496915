import React from "react";
import { useParams } from "react-router";

import config from "../../../config/config";

import "./AnalyticalMethods.scss";

const SupplementsMethods = () => {
  const { year } = useParams();

  const download = (refNum) => {
    const url = `${config.baseUri}/analyticalMethods?year=${year}&fileName=${refNum}&volumeNumber=3`;
    window.location = url;
  };

  return (
    <div>
      <h1>Analytical Methods</h1>
      <ul>
        <li>
          <button onClick={() => download("Supplement Methods")}>
            Supplement Methods
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SupplementsMethods;
