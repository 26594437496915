import React from "react";
import { useParams } from "react-router";
import HealthyPeople20302021 from "./HealthyPeople2030_2021";
import HealthyPeople20302022 from "./HealthyPeople2030_2022";
import HealthyPeople20302023 from "./HealthyPeople2030_2023";

const HealthyPeople2030 = () => {
  const { year } = useParams();
  if (year === "2021") {
    return <HealthyPeople20302021 />;
  } else if (year === "2022") {
    return <HealthyPeople20302022 />;  
  } else if (year === "2023") {
    return <HealthyPeople20302023 />;  
  } else {
    return null;
  }
};

export default HealthyPeople2030;
